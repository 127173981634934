var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [_vm._v("Vraag toevoegen")]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? [
                    _c("loader-card", {
                      attrs: { type: "spinner--center", flat: true }
                    })
                  ]
                : _vm._e(),
              !_vm.isLoading
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                clearable: "",
                                items: _vm.formTypes,
                                "hide-details": "",
                                label: "Form type"
                              },
                              model: {
                                value: _vm.questionData.form_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "form_type", $$v)
                                },
                                expression: "questionData.form_type"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { type: "number", label: "Section" },
                              model: {
                                value: _vm.questionData.section,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "section", $$v)
                                },
                                expression: "questionData.section"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { type: "number", label: "sort order" },
                              model: {
                                value: _vm.questionData.sort_order,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "sort_order", $$v)
                                },
                                expression: "questionData.sort_order"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Key" },
                              model: {
                                value: _vm.questionData.key,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "key", $$v)
                                },
                                expression: "questionData.key"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-textarea", {
                              attrs: { label: "Label" },
                              model: {
                                value: _vm.questionData.label,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "label", $$v)
                                },
                                expression: "questionData.label"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.questionTypes,
                                "hide-details": "",
                                label: "Type"
                              },
                              model: {
                                value: _vm.questionData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "type", $$v)
                                },
                                expression: "questionData.type"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "tw-mt-16",
                              attrs: {
                                clearable: "",
                                items: _vm.questionsWithOptions,
                                "search-input": _vm.questionSearch,
                                "hide-details": "",
                                loading: _vm.isLoadingQuestions,
                                "allow-overflow": false,
                                label: "Parent question",
                                "item-text": "label",
                                "item-value": "uuid",
                                "return-object": "",
                                "no-filter": ""
                              },
                              on: {
                                keyup: _vm.debouncedSearchQuestions,
                                "update:searchInput": function($event) {
                                  _vm.questionSearch = $event
                                },
                                "update:search-input": function($event) {
                                  _vm.questionSearch = $event
                                }
                              },
                              model: {
                                value: _vm.parentQuestion,
                                callback: function($$v) {
                                  _vm.parentQuestion = $$v
                                },
                                expression: "parentQuestion"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.parentQuestion
                          ? [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      clearable: "",
                                      items: _vm.parentQuestion.options,
                                      "hide-details": "",
                                      label: "Parent option",
                                      "item-text": "label",
                                      "item-value": "uuid"
                                    },
                                    model: {
                                      value: _vm.questionData.parent,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.questionData,
                                          "parent",
                                          $$v
                                        )
                                      },
                                      expression: "questionData.parent"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "mi-button",
                          {
                            attrs: { color: "primary", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v("Toevoegen")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }