import { ReportType } from '@/models/ReportType';
import { Question } from '@/models/Question';
import { AxiosError } from 'axios';
import { Component, Vue } from 'vue-property-decorator';
import ErrorHandler from '@/support/ErrorHandler';
import DataTable, { TableVisibility } from '@/components/data-table/DataTable';
import EditQuestionDialog from './EditQuestionDialog/EditQuestionDialog.vue';
import CreateQuestionDialog from './CreateQuestionDialog/CreateQuestionDialog.vue';

@Component<Questions>({
  components: {
    EditQuestionDialog,
    CreateQuestionDialog,
  },
})
export default class Questions extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  public $pageTitle = 'Vragenlijst editor';

  protected isLoading = false;

  protected visibility: TableVisibility = {
    checkboxes: false,
    total: true,
    title: false,
  };

  protected reportTypes: ReportType[] = [];

  protected questionTypes = [
    'header',
    'checkbox',
    'radio',
    'textbox',
    'textarea',
    'year',
    'date',
    'number',
  ];

  protected formTypes: string[] = [
    'tcmg',
    'expert',
    'manager',
    'planning',
  ]

  protected isEditingQuestion = false;

  protected isCreatingQuestion = false;

  protected selectedQuestion: Question | null = null;

  protected tableFilters = {};
  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */

  protected created(): void {
    this.reRoute();
  }

  protected async mounted(): Promise<void> {
    this.reRoute();
    await this.getReportTypes();
  }
  // #endregion

  // #region Class methods

  protected refreshDatatable(): void {
    if (this.$refs.quetionsDatatable) {
      (this.$refs.quetionsDatatable as DataTable).refresh();
    }
  }

  protected reRoute(): void {
    if (! this.$store.state.Auth.isAtabix) {
      this.$router.push('/');
    }
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */
  protected async getReportTypes(): Promise<void> {
    this.reportTypes = await new ReportType()
      .all()
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get tableOptions(): Object {
    return {
      model: new Question().include(['parent', 'options', 'auto_answer']).filter(this.datatableFilters).sort('sort_order', 'ASC')
        .includeFlattenHeader(),
      headers: [
        {
          text: 'Volgorde',
          value: 'sort_order',
          width: '10%',
          sortable: {
            key: 'sort_order',
            order: 'ASC',
          },
        },
        {
          text: 'type',
          value: 'type',
        },
        {
          text: 'key',
          value: 'key',
          sortable: {
            key: 'key',
            order: 'ASC',
          },
          transform: (key: boolean, question: Question) => `<strong>${question.key}</strong><br>${question.label}`,
        },
        {
          text: 'Aanname',
          value: 'is_assumable',
          width: '10%',
          transform: (is_assumable: boolean) => {
            if (is_assumable) {
              return '<i aria-hidden="true" class="v-icon material-icons theme--light text__success">check</i>';
            }
          },
        },
        {
          text: 'Te formatteren',
          value: 'is_formattable',
          width: '10%',
          transform: (is_formattable: boolean) => {
            if (is_formattable) {
              return '<i aria-hidden="true" class="v-icon material-icons theme--light text__success">check</i>';
            }
          },
        },
        {
          text: 'options',
          value: 'options',
          width: '10%',
          transform: (dataTable: DataTable, question: Question) => question.options?.length || '',
        },
        {
          text: 'Section',
          value: 'section',
          width: '10%',
        },
      ],
      actions: [
        {
          type: 'view',
          label: 'view',
          icon: 'edit',
          action: async (dataTable: DataTable, question: Question) => {
            this.selectedQuestion = question;
            this.isEditingQuestion = true;
          },
          tooltip: 'Aanpassen',
        },

      ],
      filter: [],
    };
  }

  protected get datatableFilters(): any {
    return this.tableFilters;
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
