import { cloneDeep, debounce, DebouncedFunc } from 'lodash';
import { Question, Option, UpdateQuestionPayload } from '@/models/Question';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import ErrorHandler from '@/support/ErrorHandler';

@Component<EditQuestionDialog>({
  components: {
    draggable,
    DefaultDialog,
  },
})
export default class EditQuestionDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */
  @Prop()
  protected question!: Question;

  @Prop()
  protected reportType!: string;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */

  protected formTypes: string[] = ['manager', 'tcmg', 'expert', 'pre_controller'];

  protected questionsWithOptions: Question[] = [];

  protected questionData: Question | null = null;

  protected selectedParentQuestion: Question | null = null;

  protected debouncedSearchQuestions: DebouncedFunc<() => void> = debounce(this.getQuestions, 300);

  protected questionSearch = '';

  protected selectedParentQuestionOptionId = '';

  protected questionTypes = [
    'header',
    'checkbox',
    'radio',
    'textbox',
    'textarea',
    'year',
    'date',
    'number',
  ];

  protected dragOptions = {
    animation: 0,
    group: 'description',
    disabled: false,
    ghostClass: 'ghost',
  };

  protected newOptionName = '';

  protected newOptionKey = '';

  protected isLoading = true;

  protected isLoadingQuestions = true;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected mounted(): void {
    this.initialize();
  }
  // #endregion

  // #region Class methods
  protected close(): void {
    this.$emit('input', false);
  }

  protected async initialize(): Promise<void> {
    this.questionData = cloneDeep(this.question);
    await this.getQuestions();
    this.isLoading = false;
  }

  protected onDeleteOption(index: number): void {
    this.$store.dispatch('openDialog', {
      title: 'Verwijderen bevestigen',
      text: 'Weet u zeker dat u de optie wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: 'Verwijderen',
          action: () => this.deleteOption(index),
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
        },
      },
    });
  }

  protected deleteOption(index: number): void {
    if (! this.questionData) { return; }
    this.questionData?.options?.splice(index, 1);
  }

  protected onDeleteQuestion(index: number): void {
    this.$store.dispatch('openDialog', {
      title: 'Vraag verwijderen',
      text: 'Weet u zeker dat u de hele vraag wilt verwijderen?',
      type: 'warning',
      buttons: {
        confirm: {
          color: 'warning',
          text: 'Verwijderen',
          action: () => this.deleteQuestion(),
        },
        cancel: {
          text: 'Annuleren',
          color: 'text-light',
        },
      },
    });
  }

  protected async deleteQuestion(): Promise<void> {
    if (! this.questionData) { return; }
    await this.questionData.delete();

    this.$emit('reload');
    this.close();
  }

  protected addOption(): void {
    if (! this.questionData) { return; }

    this.questionData?.options?.push({
      label: this.newOptionName,
      key: this.newOptionKey,
      sort_order: '0',
    });
  }
  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async submit(): Promise<void> {
    if (! this.questionData) { return; }
    await this.questionData
      .update(this.updatePayload);

    this.$emit('reload');
    this.close();
  }

  protected async getQuestions(): Promise<void> {
    this.isLoadingQuestions = true;

    const filters = {
      search: this.questionSearch,
      report_type: this.reportType,
    };

    try {
      const questions: Question[] = await new Question().filter(filters)
        .sort('sort_order', 'ASC').includeFlattenHeader()
        .limit(300)
        .all();
      this.questionsWithOptions = questions.filter((question) => (question.options as Option[]).length);
      this.selectedParentQuestion = questions.find((question) => question.uuid === this.questionData?.parent?.question_uuid) || null;
      this.selectedParentQuestionOptionId = this.questionData?.parent?.question_option_uuid || '';
    } catch (error) {
      ErrorHandler.network(error);
    }

    this.isLoadingQuestions = false;
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */

  protected get updatePayload(): UpdateQuestionPayload {
    return {
      label: this.questionData?.label || '',
      key: this.questionData?.key || '',
      report_type_id: this.reportType || '',
      sort_order: this.questionData?.sort_order || '',
      type: this.questionData?.type || '',
      section: this.questionData?.section || undefined,
      form_type: this.questionData?.form_type || '',
      is_formattable: this.questionData?.is_formattable || false,
      is_assumable: this.questionData?.is_assumable || false,
      is_field_question: this.questionData?.is_field_question || false,
      parent: this.selectedParentQuestionOptionId || '',
      options: this.questionData?.options?.map((option, index) => ({
        uuid: option.uuid,
        label: option.label,
        key: option.key,
        sort_order: index,
      })) || [],
    };
  }

  protected get isRadio(): boolean {
    return this.questionData?.type === 'radio';
  }

  protected get isCheckbox(): boolean {
    return this.questionData?.type === 'checkbox';
  }
  // #endregion

  // #region @Watchers
  @Watch('selectedParentQuestion')
  protected selectedParentQuestionChanged() {
    if (! this.selectedParentQuestion) {
      this.selectedParentQuestionOptionId = '';
    }
  }
  // #endregion
}
