var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-layout",
        { attrs: { wrap: "" } },
        [
          _c("v-flex", { attrs: { xs6: "", "py-0": "" } }, [
            _c("h2", { staticClass: "elementSubTitle" }, [
              _vm._v("Vragenlijst")
            ])
          ]),
          _c(
            "v-flex",
            { staticClass: "relative", attrs: { xs6: "", "text-right": "" } },
            [
              _c(
                "div",
                { staticClass: "elementTitleButton absolute" },
                [
                  _vm.tableFilters.report_type
                    ? _c(
                        "MiButton",
                        {
                          staticClass: "mx-1",
                          attrs: {
                            color: "primary",
                            small: "true",
                            icon: "add"
                          },
                          nativeOn: {
                            click: function($event) {
                              _vm.isCreatingQuestion = true
                            }
                          }
                        },
                        [_vm._v(" Vraag toevoegen ")]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          ),
          _c("v-flex", { attrs: { xs12: "", md12: "" } }, [
            _c(
              "div",
              { staticClass: "elementPanel elementPanel--spaced" },
              [
                _c(
                  "v-layout",
                  [
                    _c(
                      "v-flex",
                      { attrs: { shrink: "" } },
                      [
                        _c("v-select", {
                          attrs: {
                            clearable: "",
                            items: _vm.reportTypes,
                            "hide-details": "",
                            label: "Rapporttype",
                            "item-text": "name",
                            "item-value": "uuid"
                          },
                          model: {
                            value: _vm.tableFilters.report_type,
                            callback: function($$v) {
                              _vm.$set(_vm.tableFilters, "report_type", $$v)
                            },
                            expression: "tableFilters.report_type"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.tableFilters.report_type
                      ? [
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  clearable: "",
                                  multiple: "",
                                  items: _vm.questionTypes,
                                  "hide-details": "",
                                  label: "Question type"
                                },
                                model: {
                                  value: _vm.tableFilters.types,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "types", $$v)
                                  },
                                  expression: "tableFilters.types"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("v-text-field", {
                                attrs: { type: "number", label: "Section" },
                                model: {
                                  value: _vm.tableFilters.section,
                                  callback: function($$v) {
                                    _vm.$set(_vm.tableFilters, "section", $$v)
                                  },
                                  expression: "tableFilters.section"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "v-flex",
                            { attrs: { shrink: "" } },
                            [
                              _c("v-select", {
                                attrs: {
                                  clearable: "",
                                  multiple: "",
                                  items: _vm.formTypes,
                                  "hide-details": "",
                                  label: "Form type"
                                },
                                model: {
                                  value: _vm.tableFilters.form_types,
                                  callback: function($$v) {
                                    _vm.$set(
                                      _vm.tableFilters,
                                      "form_types",
                                      $$v
                                    )
                                  },
                                  expression: "tableFilters.form_types"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      : _vm._e()
                  ],
                  2
                ),
                _c(
                  "v-layout",
                  { attrs: { wrap: "" } },
                  [
                    _vm.tableFilters.report_type
                      ? _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("DataTable", {
                              ref: "quetionsDatatable",
                              attrs: {
                                options: _vm.tableOptions,
                                visibility: _vm.visibility
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      ),
      _vm.isEditingQuestion
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "80%" },
              model: {
                value: _vm.isEditingQuestion,
                callback: function($$v) {
                  _vm.isEditingQuestion = $$v
                },
                expression: "isEditingQuestion"
              }
            },
            [
              _c("EditQuestionDialog", {
                attrs: {
                  question: _vm.selectedQuestion,
                  reportType: _vm.tableFilters.report_type
                },
                on: { reload: _vm.refreshDatatable },
                model: {
                  value: _vm.isEditingQuestion,
                  callback: function($$v) {
                    _vm.isEditingQuestion = $$v
                  },
                  expression: "isEditingQuestion"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isCreatingQuestion
        ? _c(
            "v-dialog",
            {
              attrs: { "max-width": "80%", persistent: "" },
              model: {
                value: _vm.isCreatingQuestion,
                callback: function($$v) {
                  _vm.isCreatingQuestion = $$v
                },
                expression: "isCreatingQuestion"
              }
            },
            [
              _c("CreateQuestionDialog", {
                attrs: { reportType: _vm.tableFilters.report_type },
                on: { reload: _vm.refreshDatatable },
                model: {
                  value: _vm.isCreatingQuestion,
                  callback: function($$v) {
                    _vm.isCreatingQuestion = $$v
                  },
                  expression: "isCreatingQuestion"
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }