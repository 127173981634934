import { CreateQuestionPayload, Question, Option } from '@/models/Question';
import { Component, Vue, Prop } from 'vue-property-decorator';
import DefaultDialog from '@/components/dialog/DefaultDialog.vue';
import draggable from 'vuedraggable';
import ErrorHandler from '@/support/ErrorHandler';
import { AxiosError } from 'axios';
import { debounce, DebouncedFunc } from 'lodash';

@Component<CreateQuestionDialog>({
  components: {
    DefaultDialog,
    draggable,
  },
})
export default class CreateQuestionDialog extends Vue {
  // #region @Props
  /**
  * ie.
  * @Prop()
  * protected user!: User
  */

  @Prop()
  protected reportType!: string;
  // #endregion

  // #region @Refs
  /**
  * ie.
  * @Ref()
  * readonly anotherComponent!: AnotherComponent
  */
  // #endregion

  // #region Class properties
  /**
  * ie.
  * protected isLoading = true;
  * * protected company: Company | null = null;
  */
  protected questionData: CreateQuestionPayload = {};

  protected parentQuestion: Question | null = null;

  protected questionsWithOptions: Question[] = [];

  protected debouncedSearchQuestions: DebouncedFunc<() => void> = debounce(this.getQuestions, 300);

  protected questionSearch = '';

  protected questionTypes = [
    'header',
    'checkbox',
    'radio',
    'textbox',
    'textarea',
    'year',
    'date',
    'number',
  ];

  protected formTypes: string[] = ['manager', 'tcmg', 'expert', 'pre_controller'];

  protected isLoading = true;

  protected isLoadingQuestions = true;

  // #endregion

  // #region Lifecycle Hooks / Init
  /**
  * ie.
  * protected mounted(): void {
  *  console.log('MyComponent has Mounted');
  * }
  */
  protected async mounted(): Promise<void> {
    await this.getQuestions();
    this.isLoading = false;
  }
  // #endregion

  // #region Class methods
  protected close(): void {
    this.$emit('input', false);
  }

  // #endregion

  // #region Async methods
  /**
  * ie.
  * protected async fetchUserCompany(): Promise<void> {
  *  this.company = await new Company().filter({user: this.user.id}).all();
  * }
  */

  protected async submit(): Promise<void> {
    if (! this.questionData) { return; }
    this.isLoading = true;
    await new Question()
      .create(this.createPayload)
      .then(() => {
        this.$emit('reload');
        this.close();
      })
      .catch((error: AxiosError) => {
        this.isLoading = false;
        ErrorHandler.network(error);
      });
  }

  protected async getQuestions(): Promise<void> {
    this.isLoadingQuestions = true;

    const filters = {
      search: this.questionSearch,
      report_type: this.reportType,
    };

    try {
      const questions: Question[] = await new Question().filter(filters)
        .sort('sort_order', 'ASC').includeFlattenHeader()
        .limit(300)
        .all();
      this.questionsWithOptions = questions.filter((question) => (question.options as Option[]).length);
    } catch (error) {
      ErrorHandler.network(error);
    }

    this.isLoadingQuestions = false;
  }

  // #endregion

  // #region Getters & Setters
  /**
  * ie.
  * protected get companyName(): string {
  *  return this?.company.name || 'N/A';
  * }
  */
  protected get createPayload(): CreateQuestionPayload {
    return {
      label: this.questionData?.label || '',
      key: this.questionData?.key || '',
      report_type_id: this.reportType || '',
      sort_order: this.questionData?.sort_order || '',
      type: this.questionData?.type || '',
      parent: this.questionData?.parent || '',
      section: this.questionData?.section || '',
      form_type: this.questionData?.form_type || '',
    };
  }

  protected get isRadio(): boolean {
    return this.questionData?.type === 'radio';
  }

  protected get isCheckbox(): boolean {
    return this.questionData?.type === 'checkbox';
  }
  // #endregion

  // #region @Watchers
  /**
  * ie.
  * @Watch('company')
  * protected companyChanged() {
  *  console.log('Company Changed') ;
  * }
  */
  // #endregion
}
