var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "DefaultDialog",
        { on: { close: _vm.close } },
        [
          _c("template", { slot: "header" }, [_vm._v("Vraag aanpassen")]),
          _c(
            "template",
            { slot: "content" },
            [
              _vm.isLoading
                ? [
                    _c("loader-card", {
                      attrs: { type: "spinner--center", flat: true }
                    })
                  ]
                : _vm._e(),
              !_vm.isLoading && _vm.questionData
                ? [
                    _c(
                      "v-layout",
                      { attrs: { wrap: "" } },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                clearable: "",
                                items: _vm.formTypes,
                                "hide-details": "",
                                label: "Form type"
                              },
                              model: {
                                value: _vm.questionData.form_type,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "form_type", $$v)
                                },
                                expression: "questionData.form_type"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { type: "number", label: "Section" },
                              model: {
                                value: _vm.questionData.section,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "section", $$v)
                                },
                                expression: "questionData.section"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { type: "number", label: "sort order" },
                              model: {
                                value: _vm.questionData.sort_order,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "sort_order", $$v)
                                },
                                expression: "questionData.sort_order"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-text-field", {
                              attrs: { label: "Key" },
                              model: {
                                value: _vm.questionData.key,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "key", $$v)
                                },
                                expression: "questionData.key"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-textarea", {
                              attrs: { label: "Label" },
                              model: {
                                value: _vm.questionData.label,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "label", $$v)
                                },
                                expression: "questionData.label"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-autocomplete", {
                              staticClass: "tw-mt-16",
                              attrs: {
                                clearable: "",
                                items: _vm.questionsWithOptions,
                                "search-input": _vm.questionSearch,
                                "hide-details": "",
                                loading: _vm.isLoadingQuestions,
                                "allow-overflow": false,
                                "item-text": "label",
                                label: "Parent question",
                                "item-value": "uuid",
                                "return-object": "",
                                "no-filter": ""
                              },
                              on: {
                                keyup: _vm.debouncedSearchQuestions,
                                "update:searchInput": function($event) {
                                  _vm.questionSearch = $event
                                },
                                "update:search-input": function($event) {
                                  _vm.questionSearch = $event
                                }
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function(ref) {
                                      var item = ref.item
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                item.key + " | " + item.label
                                              ) +
                                              " "
                                          )
                                        ])
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                624774092
                              ),
                              model: {
                                value: _vm.selectedParentQuestion,
                                callback: function($$v) {
                                  _vm.selectedParentQuestion = $$v
                                },
                                expression: "selectedParentQuestion"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.selectedParentQuestion
                          ? [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      clearable: "",
                                      items: _vm.selectedParentQuestion.options,
                                      "hide-details": "",
                                      label: "Parent option",
                                      "item-text": "label",
                                      "item-value": "uuid"
                                    },
                                    model: {
                                      value: _vm.selectedParentQuestionOptionId,
                                      callback: function($$v) {
                                        _vm.selectedParentQuestionOptionId = $$v
                                      },
                                      expression:
                                        "selectedParentQuestionOptionId"
                                    }
                                  })
                                ],
                                1
                              )
                            ]
                          : _vm._e(),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: "is_assumable",
                                "hide-details": "",
                                color: "#a09b1b"
                              },
                              model: {
                                value: _vm.questionData.is_assumable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.questionData,
                                    "is_assumable",
                                    $$v
                                  )
                                },
                                expression: "questionData.is_assumable"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: "is_formattable",
                                "hide-details": "",
                                color: "#a09b1b"
                              },
                              model: {
                                value: _vm.questionData.is_formattable,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.questionData,
                                    "is_formattable",
                                    $$v
                                  )
                                },
                                expression: "questionData.is_formattable"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-checkbox", {
                              attrs: {
                                label: "is_field_question",
                                "hide-details": "",
                                color: "#a09b1b"
                              },
                              model: {
                                value: _vm.questionData.is_field_question,
                                callback: function($$v) {
                                  _vm.$set(
                                    _vm.questionData,
                                    "is_field_question",
                                    $$v
                                  )
                                },
                                expression: "questionData.is_field_question"
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _c("v-select", {
                              attrs: {
                                items: _vm.questionTypes,
                                "hide-details": "",
                                label: "Type"
                              },
                              model: {
                                value: _vm.questionData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.questionData, "type", $$v)
                                },
                                expression: "questionData.type"
                              }
                            })
                          ],
                          1
                        ),
                        _vm.isRadio || _vm.isCheckbox
                          ? _c(
                              "v-flex",
                              { attrs: { xs12: "" } },
                              [
                                _c(
                                  "draggable",
                                  {
                                    staticClass: "drag-container",
                                    model: {
                                      value: _vm.questionData.options,
                                      callback: function($$v) {
                                        _vm.$set(
                                          _vm.questionData,
                                          "options",
                                          $$v
                                        )
                                      },
                                      expression: "questionData.options"
                                    }
                                  },
                                  _vm._l(_vm.questionData.options, function(
                                    element,
                                    index
                                  ) {
                                    return _c(
                                      "div",
                                      {
                                        key: "option-" + index,
                                        staticClass: "drag-item"
                                      },
                                      [
                                        _c(
                                          "v-layout",
                                          { attrs: { "align-center": "" } },
                                          [
                                            _c(
                                              "v-flex",
                                              { attrs: { shrink: "" } },
                                              [
                                                _c("v-icon", [
                                                  _vm._v("drag_indicator")
                                                ])
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              [
                                                _c("v-text-field", {
                                                  attrs: { label: "Label" },
                                                  model: {
                                                    value: element.label,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        element,
                                                        "label",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "element.label"
                                                  }
                                                })
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-flex",
                                              [
                                                _c("v-text-field", {
                                                  attrs: { label: "Key" },
                                                  model: {
                                                    value: element.key,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        element,
                                                        "key",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "element.key"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          [
                                            _c(
                                              "v-tooltip",
                                              {
                                                attrs: { bottom: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function(ref) {
                                                        var on = ref.on
                                                        return [
                                                          _c(
                                                            "v-icon",
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "link",
                                                                on: {
                                                                  click: function(
                                                                    $event
                                                                  ) {
                                                                    return _vm.onDeleteOption(
                                                                      index
                                                                    )
                                                                  }
                                                                }
                                                              },
                                                              on
                                                            ),
                                                            [_vm._v("delete")]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v("Optie verwijderen")
                                                ])
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                ),
                                _c(
                                  "v-layout",
                                  {
                                    attrs: {
                                      "align-center": "",
                                      "justify-space-between": ""
                                    }
                                  },
                                  [
                                    _c("v-flex", {
                                      staticStyle: { width: "50px" }
                                    }),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label:
                                              "Nieuwe optie toevoegen: Label"
                                          },
                                          model: {
                                            value: _vm.newOptionName,
                                            callback: function($$v) {
                                              _vm.newOptionName = $$v
                                            },
                                            expression: "newOptionName"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { xs6: "" } },
                                      [
                                        _c("v-text-field", {
                                          attrs: {
                                            label: "Nieuwe optie toevoegen: Key"
                                          },
                                          model: {
                                            value: _vm.newOptionKey,
                                            callback: function($$v) {
                                              _vm.newOptionKey = $$v
                                            },
                                            expression: "newOptionKey"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-flex",
                                      { attrs: { "pr-3": "" } },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { bottom: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-icon",
                                                        _vm._g(
                                                          {
                                                            staticClass: "link",
                                                            on: {
                                                              click:
                                                                _vm.addOption
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [_vm._v("add")]
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              false,
                                              375158596
                                            )
                                          },
                                          [
                                            _c("span", [
                                              _vm._v("Optie toevoegen")
                                            ])
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ],
                      2
                    )
                  ]
                : _vm._e()
            ],
            2
          ),
          _c(
            "template",
            { slot: "actions" },
            [
              _c(
                "v-layout",
                { attrs: { "justify-end": "", "align-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { shrink: "" } },
                    [
                      [
                        _c(
                          "span",
                          {
                            staticClass: "fadedText link mr-3",
                            on: { click: _vm.close }
                          },
                          [_vm._v("Annuleren")]
                        ),
                        _c(
                          "MiButton",
                          {
                            staticClass: "mr-3",
                            attrs: { color: "warning", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.onDeleteQuestion($event)
                              }
                            }
                          },
                          [_vm._v(" Verwijderen ")]
                        ),
                        _c(
                          "MiButton",
                          {
                            attrs: { color: "primary", small: "true" },
                            nativeOn: {
                              click: function($event) {
                                return _vm.submit($event)
                              }
                            }
                          },
                          [_vm._v("Aanpassen")]
                        )
                      ]
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }